import * as ReactDropdownMenu from "@radix-ui/react-dropdown-menu"
import * as React from "react"

import { cva } from "class-variance-authority"
import { cn } from "../../lib/utils/classname.ts"

const DropdownMenu = ReactDropdownMenu.Root
const DropdownMenuArrow = ReactDropdownMenu.Arrow
const DropdownMenuCheckboxItem = ReactDropdownMenu.CheckboxItem
const DropdownMenuGroup = ReactDropdownMenu.Group
const DropdownMenuItemIndicator = ReactDropdownMenu.ItemIndicator
const DropdownMenuLabel = ReactDropdownMenu.Label
const DropdownMenuPortal = ReactDropdownMenu.Portal
const DropdownMenuRadioGroup = ReactDropdownMenu.RadioGroup
const DropdownMenuRadioItem = ReactDropdownMenu.RadioItem
const DropdownMenuSeparator = ReactDropdownMenu.Separator
const DropdownMenuSub = ReactDropdownMenu.Sub
const DropdownMenuSubContent = ReactDropdownMenu.SubContent
const DropdownMenuSubTrigger = ReactDropdownMenu.SubTrigger
const DropdownMenuTrigger = ReactDropdownMenu.Trigger

const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof ReactDropdownMenu.Content>,
  React.ComponentPropsWithoutRef<typeof ReactDropdownMenu.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <ReactDropdownMenu.Portal>
    <ReactDropdownMenu.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        "z-50 min-w-24 overflow-hidden rounded-sm border border-gray-5 bg-background p-1 shadow-sm flex flex-col gap-1",
        "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:blur-[1px] data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        className,
      )}
      {...props}
    />
  </ReactDropdownMenu.Portal>
))
DropdownMenuContent.displayName = "DropdownMenuContent"

const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof ReactDropdownMenu.Item>,
  React.ComponentPropsWithoutRef<typeof ReactDropdownMenu.Item>
>(({ className, ...props }, ref) => (
  <ReactDropdownMenu.Item
    ref={ref}
    className={classNameForDropdownMenuItem({
      className,
    })}
    {...props}
  />
))

export const classNameForDropdownMenuItem = cva(
  "relative flex select-none items-center rounded-sm p-2 text-sm outline-none transition-colors hover:bg-gray-3 focus:bg-gray-3 data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
)

DropdownMenuItem.displayName = "DropdownMenuItem"

export {
  DropdownMenuArrow as Arrow,
  DropdownMenuCheckboxItem as CheckboxItem,
  DropdownMenuContent as Content,
  DropdownMenuGroup as Group,
  DropdownMenuItem as Item,
  DropdownMenuItemIndicator as ItemIndicator,
  DropdownMenuLabel as Label,
  DropdownMenuPortal as Portal,
  DropdownMenuRadioGroup as RadioGroup,
  DropdownMenuRadioItem as RadioItem,
  DropdownMenu as Root,
  DropdownMenuSeparator as Separator,
  DropdownMenuSub as Sub,
  DropdownMenuSubContent as SubContent,
  DropdownMenuSubTrigger as SubTrigger,
  DropdownMenuTrigger as Trigger,
}
